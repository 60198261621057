import PropTypes from 'prop-types';
import React from 'react';
import { css } from '@emotion/core';
import { graphql } from 'gatsby';
import Layout from '../../organisms/Layout/Layout';
import Section from '../../molecules/Section/Section';
import AlgoliaSearchInput from '../../organisms/AlgoliaSearchInput/AlgoliaSearchInput';
import { breakpoints, unitConverter as uc } from '../../../styles/base';
import useLocalize from '../../../hooks/useLocalize';

const SearchPage = ({ data: rawData, pageContext: { locale } }) => {
  if (!rawData) {
    return null;
  }
  const data = useLocalize(rawData, locale);

  const sectionCss = css`
    display: flex;
    justify-content: center;
    width: 100%;
    padding: ${uc('150px 50px 50px 50px')};

    @media (${breakpoints.largeTablet}) {
      padding: ${uc('100px 50px 50px 50px')};
    }
  `;
  return (
    <Layout
      locale={locale}
      menuColor="primary"
      routeName={data.sanitySearchpage.title}
      sanityId={data.sanitySearchpage._id}
    >
      <Section css={sectionCss}>
        <AlgoliaSearchInput
          suggestionLinks={data.sanitySearchpage.suggestionLinks}
        />
      </Section>
    </Layout>
  );
};

SearchPage.propTypes = {
  data: PropTypes.shape({ sanitySearchpage: {} }),
  pageContext: PropTypes.shape({ locale: PropTypes.string }).isRequired,
};

SearchPage.defaultProps = {
  data: {},
};

export const query = graphql`
  query($slug: String) {
    sanitySearchpage(route: { slug: { current: { eq: $slug } } }) {
      _id
      title: _rawTitle(resolveReferences: { maxDepth: 100 })
      suggestionLinks {
        link {
          ... on SanityExternalLink {
            externalLink: _rawExternalLink(resolveReferences: { maxDepth: 100 })
            _key
          }
          ... on SanityRoute {
            slug {
              current
            }
            enabled
            _type
          }
        }
        text: _rawText(resolveReferences: { maxDepth: 100 })
      }
    }
  }
`;

export default SearchPage;
