import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { Link, useStaticQuery, graphql } from 'gatsby';
import { css } from '@emotion/core';
import { Highlight } from 'react-instantsearch-dom';
import {
  breakpoints,
  colors,
  fontSizes,
  unitConverter as uc,
} from '../../../styles/base';
import { Heading3 } from '../../atoms/Headings/Headings';
import Text from '../../atoms/Text/Text';
import { isMobile } from '../../../utils/browserUtils';
import { truncateText } from '../../../utils/textUtils';
import { LocaleContext } from '../../../context/LocaleContext';
import useLocalize from '../../../hooks/useLocalize';

const containerStyle = css`
  background-color: ${colors.white};
`;

const headingCss = css`
  margin: ${uc('0px')};

  @media (${breakpoints.mobile}) {
    font-size: ${fontSizes.eighteen};
  }
`;

const textCss = css`
  @media (${breakpoints.mobile}) {
    font-size: ${fontSizes.twelve};
  }
`;

const SearchPageHit = ({ hit }) => {
  const rawQueryResult = useStaticQuery(graphql`
    query {
      title: sanityStaticText(name: { eq: "titleNotAvailable" }) {
        content: _rawContent(resolveReferences: { maxDepth: 100 })
      }
      description: sanityStaticText(name: { eq: "descriptionNotAvailable" }) {
        content: _rawContent(resolveReferences: { maxDepth: 100 })
      }
    }
  `);
  const { locale } = useContext(LocaleContext);
  const queryResult = useLocalize(rawQueryResult, locale);
  if (hit) {
    const { description, metaDescription, metaTitle, slug, title } = hit;

    const content =
      metaDescription || description || queryResult.description.content;
    const searchTitle = metaTitle || title || queryResult.title.content;
    const parsedHit = {
      ...hit,
      title: searchTitle,
    };
    return (
      <div css={containerStyle}>
        <Heading3 css={headingCss}>
          <Link data-testid="search-hit" to={slug}>
            <Highlight hit={parsedHit} attribute="title" tagName="mark" />
          </Link>
        </Heading3>
        <Text css={textCss}>
          {truncateText(content, isMobile() ? 200 : 300)}
        </Text>
      </div>
    );
  }
  return null;
};

SearchPageHit.propTypes = {
  hit: PropTypes.shape({
    description: PropTypes.string,
    metaDescription: PropTypes.string,
    metaTitle: PropTypes.string,
    slug: PropTypes.string.isRequired,
    title: PropTypes.string,
  }).isRequired,
};

export default SearchPageHit;
